@import "../css/variables.scss";

body.jobsite-theme {
  .cta {
    .btn {
      &:hover {
        background: $grey !important;
      }
    }
  }
  .langswitcher {
    a {
      color: $white !important;
    }
  }
  .currentlang {
    color: $grey !important;
  }
  h2 {
    span {
      background: linear-gradient(0deg, $js-green 30%, transparent 0);
    }
  }
  .small-equip {
    button {
      background: $js-green;
      &:hover {
        background: $grey !important;
      }
    }
  }
  footer {
    background: $js-green;
    h2,h3,p,a {
      color: $grey;
    }
    span {
      color: $grey;
      font-weight: bold;
    }
    a {
      &:hover {
        color: $js-green;
      }
    }
  }
  // General
  ul {
    list-style-type: none;
  }
  button,
  .btn {
    transition: 0.2s;
    border-radius: 0;
    &:hover {
      text-decoration: none;
    }
  }
  // Nav
  @media screen and (min-width: 992px) {
    header .nav > li > button:hover {
      color: $js-green !important;
    }
  }
  .cart {
    border: none;
    color: #fff;
    font-size: inherit;
    padding: 0;
    background: none;
    &:hover {
      background: none !important;
    }
  }
  .badge {
    color: $js-green;
  }

  span {
    &.gtlist {
      background: $js-green;
      &:hover {
        background: $js-hover-green;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .nav-container {
      background-color: $grey;
    }
    .langswitcher {
      border-bottom: 1px solid $white;
    }
  }
  @media screen and (min-width: 992px) {
    .h-menu .navbar-right li ul.dropdown-menu li > a {
      transition: 0.2s;
      &:hover {
        background: $js-green;
        color: $grey;
      }
    }
    a {
      &.currentlang {
        color: $js-green;
      }
    }
    header .nav > li > a:hover,
    header .nav > li > a:focus {
      color: $js-green !important;
    }
  }
  // Home
  &.pgHome {
    #hero-box {
      border: 1px solid $js-dark-green;
    }
    input {
      &#home-mainsearch,
      &.form-control {
        border-radius: 0 !important;
      }
      &#home-mainsearch {
        color: $grey;
      }
    }
    p {
      &.morefilterslink {
        transition: 0.2s;
        i {
          color: $js-green;
        }
      }
    }
    .searchFoundText {
      span {
        color: $white;
        @media screen and (max-width: 992px) {
          color: $grey;
        }
      }
    }
    #home-introsectionwrap {
      background: url("../../src/images/jobsite-hero.jpg") center center
        no-repeat;
      background-size: cover;
      margin-top: 0px;
      .find-btn {
        background: $js-green;
        border: $js-green;
        border-radius: 0;
        border-bottom: none;
        &:hover {
          border-bottom: none;
          background: $js-hover-green !important;
          color: $grey !important;
        }
      }
    }
    #home-introsectionwrap,
    #sectionFamilyList {
      background-color: none;
      color: $white;
      &::after {
        border-top-color: $primary-red;
      }
    }
    .wehaveitall {
      background-color: $js-green !important;
      color: $grey !important;
      &::after {
        border-top-color: $js-green !important;
      }
    }
    #sectionFamilyList {
      background-color: $grey;

      .homeequiplink {
        text-align: center;
        font-size: 14px;
        background: $js-green;
        color: $grey;
      }

      h2 {
        color: $grey;
      }

      @media screen and (min-width: 992px) {
        margin-top: -103px;
      }
    }
    #ironclad-section,
    #sectionFamilyList {
      @media screen and (max-width: 992px) {
        padding: 40px 0px 40px 0px;
      }
      @media screen and (min-width: 992px) {
        padding: 100px 0px 100px 0px;
      }
    }
    #ironclad-section {
      background: none;
      background-color: $js-green;
    }
  }
  // Page Listing / Page Detail
  &.pgListing,
  &.pgDetail {
    // Hide horizontal scroll bar on Google Form
    iframe {
      overflow: hidden;
      height: 1630px;
      border: none;
    }
  }
  // Page Listing
  &.pgListing {
    .col-filters input.form-control {
      border-color: $js-green;
    }
    .btn.top-phone {
      font-size: 25px;
      font-weight: 800;
      color: $js-dark-green;
      &:hover {
        color: #333 !important;
        background-color: $js-green;
        border-color: $js-green;
        box-shadow: none;
      }
    }
    .btn-top-email {
      background-color: $js-green;
      &:hover {
        background-color: $grey !important;
        border-color: $js-green !important;
        color: $white !important;
      }
      span {
        font-weight: 800;
      }
    }
    .product-keyword,
    .input-group-addon {
      border-radius: 0;
    }
    #list-sorter {
      label {
        padding-right: 5px;
      }
    }
    .product-price {
      p {
        color: black;
        font-weight: 800 !important;
        font-size: 20px !important;
      }
    }
    #mosaic {
      transition: 0.2s;
      .product-wrapper {
        transition: 0.2s;
        &:hover {
          transition: 0.2s;
          background-color: $white;
          // transform: translate3D(0, -5px, 0);
          box-shadow: 0 10px 20px #c7c7c7;
        }
      }
    }
    .rc-slider-track {
      background-color: $js-green;
    }
    .favestar {
      i {
        &:hover {
          color: $js-green;
        }
      }
    }
  }
  // Page Detail
  &.pgDetail {
    .current-avail {
      .green {
        color: $js-dark-green;
      }
      p {
        &#current-avail {
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
    .breadcrumbs a,
    .backtolink {
      color: $js-dark-green;
    }
    .btn.top-phone {
      color: $js-dark-green;
      &:hover {
        background-color: $js-green !important;
        border-color: $js-green !important;
      }
    }
    .btn-primary {
      background-color: $js-green !important;
    }
  }
  // Formspree Form
  .formspree-form {
    input,
    select {
      margin-bottom: 20px;
    }
    button {
      background-color: $js-green;
      color: $grey;
      border: none;
      font-weight: 800;
      &:focus,
      &:hover {
        background-color: $grey;
        color: $white;
      }
    }
  }
  // Inspection Report
  .periodic-annual-inspection-report {
    .inspection-header {
      padding-bottom: 10px;
      strong {
        margin-right: 5px;
        &#id {
          margin-left: 15px;
        }
      }
    }
    .accordion__button {
      background: #c0d93e;
      transition: 0.2s;
      &:focus,
      &:hover {
        background: #333;
        color: #fff;
      }
    }
    .table {
      margin-bottom: 0px;
    }
    .table > thead > tr > th {
      border-bottom: none;
      font-style: italic;
    }
    tr {
      border-bottom: 0.5px solid #ddd;
    }
    td {
      border-top: none !important;
      line-height: 1.8;
    }
  }
  #listing-header-title-wrap {
    background: $grey !important;
  }
  .ctabuttons .btn {
    background: $js-green !important;
  }
  select {
    border-color: $js-green !important;
    color: $grey;
  }
  .rc-slider-handle {
    border: solid 2px $js-dark-green;
    background-color: $js-dark-green;
  }
  #filtersSection .btn-default {
    background: $js-green;
  }
  .product-info-column .btn {
    background: $js-green;
  }
  .find-out-more,
  .detailsection_contact {
    background: $js-green;
  }
  #detail-header-title-wrap {
    background: $grey;
  }
  .product-info-column {
    overflow-wrap: break-word;
  }
  header .nav > li > a {
    color: $white;
  }
}
